import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setPartnerId, setPartnerRevision } from '@stores/partner/partner-revision/partner-revision.actions';
import { PartnerWebservice } from '@webservices/partner-api/partner.webservice';
import { filter, map, switchMap } from 'rxjs';

export const setPartnerRevision$ = createEffect(
  (actions$ = inject(Actions), partnerWebservice = inject(PartnerWebservice)) =>
    actions$.pipe(
      ofType(setPartnerId),
      map((action) => action.partnerId),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => partnerWebservice.getPartnerRevision({ partnerId })),
      map((partnerRevision) => setPartnerRevision({ partnerRevision }))
    ),
  { functional: true }
);
