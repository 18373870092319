var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ErrorHandler, Injectable } from '@angular/core';
import Bugsnag, { Client } from '@bugsnag/js';
let BugsnagErrorHandler = class BugsnagErrorHandler extends ErrorHandler {
  constructor(client) {
    super();
    if (client) {
      this.bugsnagClient = client;
    } else {
      this.bugsnagClient = Bugsnag._client;
    }
  }
  handleError(error) {
    const handledState = {
      severity: 'error',
      severityReason: {
        type: 'unhandledException'
      },
      unhandled: true
    };
    const event = this.bugsnagClient.Event.create(error, true, handledState, 'angular error handler', 1);
    if (error.ngDebugContext) {
      event.addMetadata('angular', {
        component: error.ngDebugContext.component,
        context: error.ngDebugContext.context
      });
    }
    this.bugsnagClient._notify(event);
    ErrorHandler.prototype.handleError.call(this, error);
  }
};
BugsnagErrorHandler = __decorate([Injectable(), __metadata("design:paramtypes", [Client])], BugsnagErrorHandler);
export { BugsnagErrorHandler };
const ɵ0 = client => {
  return new BugsnagErrorHandler(client);
};
const plugin = {
  load: ɵ0,
  name: 'Angular'
};
export default plugin;
export { ɵ0 };
