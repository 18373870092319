import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { InterserviceNavbarComponent } from '@commons/interservice-navbar/interservice-navbar.component';
import { NavigationMenuComponent } from '@commons/navigation-menu/navigation-menu.component';
import { VersionComponent } from '@commons/version/version.component';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { selectCurrentRoute, selectUrl } from '@stores/router/router.selectors';
import { WzbBoNotificationsComponent } from '@wizbii-components/bo-angular-ui';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-core',
  imports: [
    AsyncPipe,
    RouterOutlet,
    MatProgressBarModule,
    NavigationMenuComponent,
    VersionComponent,
    InterserviceNavbarComponent,
    WzbBoNotificationsComponent,
  ],
  template: `
    <wzb-notifications />
    @if (loadingBarService.value$ | async) {
      <mat-progress-bar class="progress-bar" mode="indeterminate" />
    }
    <app-interservice-navbar class="interservice-navbar" />
    <app-version class="version" />

    <main class="main">
      <app-navigation-menu class="aside" />

      <section class="section">
        <router-outlet />
      </section>
    </main>
  `,
  styles: [
    `
      .main {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
      }

      .aside {
        position: sticky;
        top: 0;
        height: 100svh;
        z-index: 3;
      }

      .version {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
      }

      /* Warning: keep z-index higher than header in builder-layout component */
      .interservice-navbar {
        position: fixed;
        bottom: 1.25rem;
        left: 2.375rem;
        z-index: 4;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CoreComponent {
  readonly loadingBarService = inject(LoadingBarService);
  readonly #store = inject(Store);
  readonly #platformId = inject(PLATFORM_ID);
  readonly #ngZone = inject(NgZone);
  readonly #matIconRegistry = inject(MatIconRegistry);
  readonly #domSanitizer = inject(DomSanitizer);

  constructor() {
    this.#setIconRegistry();
    this.#webVitals();
  }

  readonly #setIconRegistry = (): void => {
    this.#ngZone.runOutsideAngular(() =>
      this.#matIconRegistry.addSvgIconSet(
        this.#domSanitizer.bypassSecurityTrustResourceUrl(`${environment.deployAssetsUrl}/sprites/icons.svg`)
      )
    );
  };

  readonly #webVitals = (): void => {
    combineLatest([this.#store.select(selectCurrentRoute), this.#store.select(selectUrl)]).subscribe(
      ([routerParams, url]: [routerParams: Record<string, string>[], url: string]) => {
        if (url) {
          const params: WebVitalsParams = {
            params: routerParams,
            path: url,
            applicationId: environment.applicationId,
            envFqdn: environment.domain,
          };
          const options: WebVitalsOptions = {
            dev: environment.platform === 'local',
            debug: environment.platform === 'local',
            browser: isPlatformBrowser(this.#platformId),
          };
          sendWebVitals(params, options);
        }
      }
    );
  };
}
