import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CurrentRouteService } from '@commons/services/current-route.service';
import { environment } from '@env/environment';
import { map } from 'rxjs';

@Component({
  selector: 'app-version',
  standalone: true,
  imports: [AsyncPipe],
  template: `
    @if (vm$ | async; as vm) {
      <mark>Version: {{ vm.version }}&nbsp;/&nbsp;{{ vm.route }}</mark>
    }
  `,
  styles: [
    `
      :host {
        background-color: rgb(255 255 255 / 50%);
        padding-inline: 1rem;
        font-size: small;
        mark {
          background-color: transparent;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionComponent {
  readonly vm$ = inject(CurrentRouteService)
    .get()
    .pipe(map((route) => ({ route, version: environment.version })));
}
