import { BugsnagHandler, GlobalErrorHandler } from './error-handler';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  ErrorHandler,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular/dist/esm2015';
import { FileWebService } from '@commons/form-controls/file-picker/services/file.web-service';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/core.component';
import { reqBodyInterceptorFn } from '@core/interceptors/req-body.interceptor';
import { JwtService } from '@core/services/jwt/jwt.service';
import { environment } from '@env/environment';
import { FEATURES_ROUTES } from '@features/routes';
import { RoleEnum } from '@models/commons/role';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { Store as NgrxStore, provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import * as bankingProductEffect from '@stores/banking-products/banking-product.effects';
import { bankingProductFeatureKey, bankingProductReducer } from '@stores/banking-products/banking-product.reducers';
import * as menuEffects from '@stores/builder-menu/builder-menu.effects';
import { menuFeatureKey, menuReducer } from '@stores/builder-menu/builder-menu.reducer';
import * as lockPlatformAndServiceEffect from '@stores/lock/lock.effects';
import { lockFeatureKey, lockReducer } from '@stores/lock/lock.reducers';
import * as partnerRevisionEffect from '@stores/partner/partner-revision/partner-revision.effects';
import {
  partnerRevisionFeatureKey,
  partnerRevisionReducer,
} from '@stores/partner/partner-revision/partner-revision.reducers';
import * as servicesCategoriesEffect from '@stores/service/services-categories/services-categories.effects';
import {
  servicesCategoriesFeatureKey,
  servicesCategoriesReducer,
} from '@stores/service/services-categories/services-categories.reducer';
import * as servicesEffect from '@stores/service/services/services.effects';
import { servicesFeatureKey, servicesReducer } from '@stores/service/services/services.reducer';
import { AlgoliaCredentialsService } from '@webservices/algolia/algolia-credentials.service';
import {
  FILE_PICKER_SERVICE_TOKEN,
  MUST_BE_AUTHORIZED_GUARD_CONFIG,
  PageUnauthorizedModule,
} from '@wizbii-components/bo-angular-ui';
import {
  ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
  CredentialsFetcher,
  CredentialsFetchers,
} from '@wizbii-utils/angular/algolia';
import { JWT_SERVICE_TOKEN, JwtInterceptorFn } from '@wizbii-utils/angular/jwt';
import {
  NGRX_JWT_STATE_CONFIG,
  jwtDeleteEffect,
  jwtDeleteHardEffect,
  jwtFeatureKey,
  jwtReducer,
  jwtSetEffect,
  loadJwt,
  roles,
} from '@wizbii-utils/angular/stores';
import {
  AUTHENTICATION_API_CONFIG,
  AuthenticationWebservice,
  CityWebservice,
  FILE_API_CONFIG,
  FileWebservice,
  PLACE_API_CONFIG,
} from '@wizbii-utils/angular/webservices';
import { ScrollToModule } from '@wizbii/ngx-scroll-to';
import { LocaleEnum } from '@wizbii/utils/models';

const client = Bugsnag.start(bugsnagConfig);

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeFr, 'fr');

bootstrapApplication(CoreComponent, {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(BrowserModule),
    provideAnimations(),
    provideHttpClient(withInterceptors([JwtInterceptorFn, reqBodyInterceptorFn])),
    provideRouter(
      FEATURES_ROUTES,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withComponentInputBinding(),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      })
    ),

    importProvidersFrom(MatDialogModule),
    importProvidersFrom(LoadingBarHttpClientModule),
    importProvidersFrom(LoadingBarRouterModule),
    importProvidersFrom(PageUnauthorizedModule),
    importProvidersFrom(ScrollToModule.forRoot()),
    provideStore({ router: routerReducer }),
    provideState({ name: jwtFeatureKey, reducer: jwtReducer }),
    provideState({ name: lockFeatureKey, reducer: lockReducer }),
    provideState({ name: partnerRevisionFeatureKey, reducer: partnerRevisionReducer }),
    provideState({ name: bankingProductFeatureKey, reducer: bankingProductReducer }),
    provideState({ name: servicesFeatureKey, reducer: servicesReducer }),
    provideState({ name: servicesCategoriesFeatureKey, reducer: servicesCategoriesReducer }),
    provideState({ name: menuFeatureKey, reducer: menuReducer }),
    provideEffects(
      {
        loadJwt,
        jwtDeleteEffect,
        jwtSetEffect,
        jwtDeleteHardEffect,
      },
      lockPlatformAndServiceEffect,
      partnerRevisionEffect,
      bankingProductEffect,
      servicesEffect,
      servicesCategoriesEffect,
      menuEffects
    ),
    provideRouterStore({
      routerState: RouterState.Full,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    FileWebservice,
    {
      provide: FILE_PICKER_SERVICE_TOKEN,
      useClass: FileWebService,
    },
    CityWebservice,
    {
      provide: PLACE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.place,
      },
    },
    {
      provide: FILE_API_CONFIG,
      useValue: {
        baseUrl: environment.api.file,
        googleStorageUrl: environment.api.googleStorage,
        wizbiiFilesBucket: environment.wizbiiFiles,
      },
    },
    {
      provide: NGRX_JWT_STATE_CONFIG,
      useValue: {
        jwtCookieName: 'wizbii_bo',
        expiryCookieName: 'wizbii_bo_expiry',
        appEnvFqdn: environment.cookieDomain,
      },
    },
    {
      provide: BugsnagHandler,
      useFactory: () => new BugsnagErrorHandler(client),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true } },
    {
      provide: AUTHENTICATION_API_CONFIG,
      useValue: {
        appId: environment.applicationId,
        baseUrl: environment.api.authentication,
        locale: LocaleEnum.fr_FR,
        cookieDomains: environment.cookieDomain,
      },
    },
    {
      provide: MUST_BE_AUTHORIZED_GUARD_CONFIG,
      useFactory: (store: NgrxStore) => ({
        listRoles$: store.select(roles),
        listAuthorizedRoles: [
          RoleEnum.ROLE_CONFIGURATOR_LOYALTY,
          RoleEnum.ROLE_SERVICES_LOYALTY,
          RoleEnum.ROLE_SUPPORT_LOYALTY,
        ],
        platform: environment.platform,
      }),
      deps: [NgrxStore],
    },
    AuthenticationWebservice,
    AlgoliaCredentialsService,
    {
      provide: ALGOLIA_CREDENTIALS_FETCHERS_TOKEN,
      useFactory: (algoliaCredentialsService: CredentialsFetcher): CredentialsFetchers => ({
        obendy: algoliaCredentialsService,
      }),
      deps: [AlgoliaCredentialsService],
    },
    // provideStoreDevtools must always be at the end of providers
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      name: 'Obendy BO NGRX',
    }),
  ],
}).catch((err) => console.error(err));
