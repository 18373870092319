import {
  loadAllServiceCategory,
  setAllServiceCategoryFailed,
  setAllServiceCategorySuccess,
} from './services-categories.actions';
import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { ServiceCategoryWebService } from '@webservices/service/service-category.webservice';
import { catchError, map, switchMap, throwError } from 'rxjs';

export const getServicesCategoriesEffect = createEffect(
  (actions$ = inject(Actions), serviceCategoryWebService = inject(ServiceCategoryWebService)) =>
    actions$.pipe(
      ofType(loadAllServiceCategory, ROOT_EFFECTS_INIT),
      switchMap(() => serviceCategoryWebService.getAll()),
      map((servicesCategories) => setAllServiceCategorySuccess({ servicesCategories })),
      catchError((error) => {
        setAllServiceCategoryFailed();
        return throwError(() => error);
      })
    ),
  { functional: true }
);
