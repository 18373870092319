import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import {
  loadServicesList,
  setServicesListFailed,
  setServicesListSuccess,
} from '@stores/service/services/services.actions';
import { ServiceWebservice } from '@webservices/external-service-api/service.webservice';
import { catchError, map, switchMap, throwError } from 'rxjs';

export const loadServicesListEffect = createEffect(
  (actions$ = inject(Actions), serviceWebService = inject(ServiceWebservice)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT, loadServicesList),
      map((actions) => ({
        search: actions.search,
        category: actions.category,
        hitsPerPage: actions.hitsPerPage,
        page: actions.page,
      })),
      switchMap(({ search, category, hitsPerPage, page }) =>
        serviceWebService.getServicesList(search ?? '', {
          facetFilters: `category:${category ?? ''}`,
          hitsPerPage: hitsPerPage ?? 10,
          page: page ?? 0,
        })
      ),
      map((services) => {
        return setServicesListSuccess({ ...services });
      }),
      catchError((error) => {
        setServicesListFailed();

        return throwError(() => error);
      })
    ),
  { functional: true }
);
