import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { ServiceCategoryInterface } from '@models/service/service-category.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServiceCategoryWebService {
  readonly #httpClient = inject(HttpClient);

  readonly #api = `${environment.api.externalService}/external-services/categories`;
  readonly #apiAdmin = `${environment.api.externalService}/admin/category`;

  getAll(): Observable<ServiceCategoryInterface[]> {
    return this.#httpClient.get<ServiceCategoryInterface[]>(this.#api);
  }

  create(serviceCategory: ServiceCategoryInterface) {
    return this.#httpClient.post(this.#apiAdmin, { name: serviceCategory.name });
  }

  update(serviceCategory: ServiceCategoryInterface) {
    return this.#httpClient.put(`${this.#apiAdmin}/${serviceCategory.id}`, { name: serviceCategory.name });
  }
}
