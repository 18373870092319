import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { JwtServiceInterface, JwtTokens } from '@wizbii-utils/angular/jwt';
import { jwtDelete, jwtSet, jwtTokens } from '@wizbii-utils/angular/stores';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';

@Injectable()
export class JwtService implements JwtServiceInterface {
  readonly #store = inject(Store);
  readonly #authService = inject(AuthenticationWebservice);

  blacklistRoutes: (string | RegExp)[] = [
    'https://storage.googleapis.com',
    new RegExp(
      `//auth.${environment.domain}(?!(/v1/(user|auth)).*/(email|overview|password|login-token|recovery-token))`,
      'i'
    ),
  ];

  getTokens() {
    return this.#store.select(jwtTokens);
  }

  logout() {
    this.#store.dispatch(jwtDelete());
  }

  refreshToken(tokens: JwtTokens) {
    return this.#authService.refreshTokenV2(tokens, environment.applicationId);
  }

  saveLocalTokens(jwtTokens: JwtTokens) {
    this.#store.dispatch(jwtSet({ jwtTokens }));
  }
}
