import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  deleteBankingProduct,
  getBankingProductList,
  setBankingProductList,
} from '@stores/banking-products/banking-product.actions';
import { bankingProducts } from '@stores/banking-products/banking-product.selectors';
import { partnerId } from '@stores/partner/partner-revision/partner-revision.selectors';
import { BankingProductsWebservice } from '@webservices/banking-products/banking-products.webservices';
import { combineLatest, filter, map, switchMap } from 'rxjs';

export const loadAllBankingProductsEffect = createEffect(
  (actions$ = inject(Actions), bankingProductsWebService = inject(BankingProductsWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => bankingProductsWebService.getAll(partnerId)),
      map((bankingProducts) => setBankingProductList({ bankingProducts }))
    ),
  { functional: true }
);

export const getAllBankingProductsEffect = createEffect(
  (actions$ = inject(Actions), bankingProductsWebService = inject(BankingProductsWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(getBankingProductList),
      switchMap(() => store.select(partnerId)),
      filter((partnerId): partnerId is string => !!partnerId),
      switchMap((partnerId) => bankingProductsWebService.getAll(partnerId)),
      map((bankingProducts) => setBankingProductList({ bankingProducts }))
    ),
  { functional: true }
);

export const deleteBankingProductEffect = createEffect(
  (actions$ = inject(Actions), bankingProductsWebService = inject(BankingProductsWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(deleteBankingProduct),
      map((action) => action.bankingProductId),
      switchMap((bankingProductId) =>
        combineLatest([bankingProductsWebService.delete(bankingProductId), store.select(bankingProducts)])
      ),
      map(([deletedBankingProduct, bankingProducts]) => {
        const updatedState = bankingProducts.filter((bankingProduct) => bankingProduct.id !== deletedBankingProduct.id);
        return setBankingProductList({ bankingProducts: updatedState });
      })
    ),
  { functional: true }
);
