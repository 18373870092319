import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { Credentials, CredentialsFetcher } from '@wizbii-utils/angular/algolia';

@Injectable()
export class AlgoliaCredentialsService implements CredentialsFetcher {
  readonly #http = inject(HttpClient);

  fetchCredentials() {
    return this.#http.get<Credentials>(`${environment.api.obendy}/v3/_credentials/algolia`);
  }
}
